import React from "react";
import ApplicationForm from "@components/form/applicationForm";
import formQuestions from "@data/questions/exampleFormQuestions";
import Layout from "@components/layout/layout";

import "@styles/styles.scss";

export default () => {

  const formOpts = {
    userPhotoLabel: "Please provide a current photo of yourself.",
  };

  return (
    <Layout pageTitle="Apply">
      <div style={{ height: "10vh" }}></div>
      <div className="container">
        <ApplicationForm
          formQuestions={formQuestions}
          formTitle="Nanny Test Application Form"
          formType={"Test"}
          formOpts={formOpts}
        />
      </div>
    </Layout>
  );
};
